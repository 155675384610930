.addLine{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px dashed;
    margin-bottom: -0.9em;
    height: 0.9em;
    display: flex;
    justify-content: center;
    opacity: 0.1;
    transition: all .3s;
}

.addLine:hover{
    opacity: 1;
    height: 2em;
}

.addLine + .addLine{
    margin-top: 2em;
}

.addListElement{
    border: 1px dashed rgb(var(--secondary-color));
    padding: .125em .5em;
    border-radius: .5em;
    user-select: none;
    cursor:cell;
    align-items: center;
    width: max-content;
    color: rgb(var(--secondary-color));
    font-weight: bold;
}

.addListElement:hover{
    background-color: rgba(var(--secondary-color), 0.125);
}

li:has(.addListElement){
    position: relative;
    list-style-image: url('https://api.iconify.design/mdi/plus.svg?color=%23262626');
}

.plus{
    height: 1.5em;
    width: 1.5em;
    background-color: var(--bgcol);
    margin-top: -0.5em;
    border: 1px solid;
    border-radius: 0.25em;
    margin-top: -0.8em;
    user-select: none;
    cursor:cell;
    transition: all .3s;
    background: url('https://api.iconify.design/mdi/plus.svg?color=%23262626') no-repeat center center / contain;
    background-color: var(--bgcol);
    position: absolute;
}

.plus:hover{
    width: 10em;
    height: fit-content;
    padding: .5em 1em;
    border-radius: 1em;
    background: none;
    background-color: rgba(var(--background-color), 0.5);
    backdrop-filter: blur(5px);
    z-index: 100;
    border-color: rgb(var(--primary-color));
}

.plus *{
    transform: scale(0);
    transition: all .5s;
}

.plus:hover *{
    transform: scale(1);
}

.addComponentGroup{
    padding: 0.5em;
    position: relative;
}

.addComponentGroup:hover{
    color: rgb(var(--secondary-color));
}

.addComponentGroup::after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23262626' d='M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z'/%3E%3C/svg%3E");
    position: absolute;
    right: 0;
}

.addComponentGroupWrapper{
    position: absolute;
    left: calc(100% + 0.5em);
    top: 0;
    padding: 0.5em;
    padding-left: 0;
    background-color: rgba(var(--secondary-color), 1);
    border-radius: .5em;
    scale: 0;
    transform-origin: 0 1em;
    transition-duration: 0.2s;
    box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.1);
    color: rgb(var(--text-color));
}

.addComponentGroup:hover .addComponentGroupWrapper{
    scale: 1;
}

.addComponentElement{
    display: flex;
    min-width: 100%;
    width: max-content;
    padding: 0.25em 0.5em;
    background-color: transparent;
}

.addComponentElement:hover{
    text-decoration: underline;
    backdrop-filter: invert(0.5);
}

@media (prefers-color-scheme: dark){
    .plus{
        background: url('https://api.iconify.design/mdi/plus.svg?color=%23ededed') no-repeat center center / contain;
        background-color: var(--bgcol);
    }
    .addComponentGroup::after{
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23ededed' d='M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z'/%3E%3C/svg%3E");
    }

    li:has(.addListElement) { 
        list-style-image: url('https://api.iconify.design/mdi/plus.svg?color=%23ededed'); 
    }
    
}

.adminControlWrapper{
    background-color: rgba(var(--background-color), 0.75);
    padding: 0em 1em .5em 1em;
    position: sticky;
    top: 0;
    z-index: 999999;
    backdrop-filter: blur(5px);
}

.centerHeight{
    display: flex;
    align-items: center;
}

/*Switch*/
.switch {
    position: relative;
    display: inline-flex;
    width: 2.5em;
    height: 1.5em;
    margin-right: .5em;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.25em;
    width: 1.25em;
    top: 0.125em;
    left: 0.125em;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgb(var(--primary-color));
}

input:focus + .slider {
    box-shadow: 0 0 1px  rgb(var(--primary-color));
}

input:checked + .slider:before {
    transform: translateX(1em);
}

.adminSpacer{
    min-width: 1em;
    min-height: 1em;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.imageInput, 
.imageInputInText,
.uploadNotification,
.uploadNotificationInText{
    border: 1px dashed rgba(var(--primary-color));
    border-radius: 1em;
    height: 10em;
    position: relative;
    display: flex;
    padding: .5em;
    justify-content: center;
    align-items: center;
}

.imageInput,
.uploadNotification{
    width: calc(75% - 1em);
    margin-top: 1em;
    margin-left: 12.5%;
}

.imageInput::after,
.imageInputInText::after{
    content: "Datei zum hochladen auswählen";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(var(--primary-color));
    font-weight: bold;
}

.imageInput::file-selector-button, 
.imageInputInText::file-selector-button {
    display: none;
}