.timedConfigurator{
    border: 1px solid rgba(var(--secondary-color), .75);
    border-radius: 0 .5em .5em;
    position: relative;
    transition: all .2s;
}

.timedConfigurator::before{
    content: "Zeitgesteuerter Container";
    display: inline-block;
    position: absolute;
    top: -1.35em;
    left: -.5px;
    user-select: none;
    background-color: rgba(var(--secondary-color), .5);
    padding: 0 .5em;
    border-radius: .25em .25em 0 0;
}

.timedConfigurator:hover{
    background-color: rgba(var(--secondary-color), .25);
}

.timedConfigurator:has(.wrench:hover){
    border-color: rgb(var(--green-color));
    background-color: rgb(var(--green-color), .125);
}

.wrench{
    position: absolute;
    right: 0;
    top: -.75em;
}

.wrench::after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2300b31e' d='m22.61 19l-9.08-9.09c.93-2.34.47-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11L6.08 7.5L2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.39-.4.39-1.01 0-1.4m-3 1.59l-9.46-9.46c-.61.45-1.29.72-2 .82c-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09l4.24-4.24L7.24 3c1.26-.05 2.49.39 3.44 1.33a4.47 4.47 0 0 1 1.24 3.96a4.35 4.35 0 0 1-.88 1.96l9.46 9.45z'/%3E%3C/svg%3E");
    height: 1.5em;
    width: 1.5em;
    display: inline-block;
}

.wrench .configurator{
    scale: 0;
    transition: all .2s;
    transform-origin: top right;
}

.wrench:hover .configurator{
    scale: 1;
}

.configurator{
    position: absolute;
    right: 0;
    top: 1.25em;
    border: 1px solid rgba(var(--secondary-color), 1);
    padding: .5em;
    border-radius: .5em;
    background-color: rgba(var(--background-color), .75);
    backdrop-filter: blur(5px);
    min-width: max-content;
}

.configurator .spreadItems input{
    color: rgb(var(--text-color));
    background-color: rgb(var(--background-color));
    border: 1px solid rgb(var(--primary-color));
    padding: .25em .5em;
    margin: .25em 0 .25em 1em;
    border-radius: .25em;
}

.spreadItems{
    display: flex;
    justify-content: space-between;
}